import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.promise.js";
var readFileToBuffer = function readFileToBuffer(file) {
  var reader = new FileReader();
  return new Promise(function (resolve, reject) {
    reader.onabort = function () {
      return reject(new Error('aborted'));
    };
    reader.onerror = function () {
      return reject(new Error('error'));
    };
    reader.onload = function () {
      return resolve(Buffer.from(reader.result));
    };
    reader.readAsArrayBuffer(file);
  });
};
export default readFileToBuffer;